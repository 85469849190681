/* App.css */

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 10px; /* Adjust padding as needed */
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

main {
  padding-top: 60px; /* Space for the fixed header */
}

.page-heading {
  margin: 0;
}
